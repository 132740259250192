import { Navigate, Route, Routes } from "react-router-dom";
import AuthGuard from "./AuthGuard";
import Quiz from "components/quiz/quiz";
import AddQuiz from "components/quiz/addQuiz";
import EditQuiz from "components/quiz/editQuiz";
import Question from "components/question/question";
import AddQuestion from "components/question/addQuestion";
import EditQuestion from "components/question/editQuestion";

import Competition from "components/competition/competition";
import AddCompetition from "components/competition/addCompetition";
import EditCompetition from "components/competition/editCompetition";
import Participants from "components/competition/participants";
import Login from "components/login";

const AppRoutes = () => {
    return (
        <Routes>
            <Route element={<AuthGuard isProtected={false} />}>
                <Route path="/login" element={<Login />} />
            </Route>
            <Route element={<AuthGuard />} isProtected>
                <Route exact path="/" Component={Quiz} />
                <Route exact path="/quiz" Component={Quiz} />
                <Route exact path="/quiz/add" Component={AddQuiz} />
                <Route exact path="/quiz/edit/:id" Component={EditQuiz} />
                <Route exact path="/quiz/question/:quizId" Component={Question} />
                <Route exact path="/quiz/question/:quizId/add" Component={AddQuestion} />
                <Route exact path="/quiz/question/:quizId/edit/:id" Component={EditQuestion} />

                <Route exact path="/competition" Component={Competition} />
                <Route exact path="/competition/add" Component={AddCompetition} />
                <Route exact path="/competition/edit/:id" Component={EditCompetition} />
                <Route exact path="/competition/participants/:id" Component={Participants} />
            </Route>
            <Route path="*" element={<Navigate to="/login" />} />
        </Routes>
    )
}

export default AppRoutes;
