exports.types = [
    
    {
        id: 4,
        name: "Multiple Choice"
    },
    {
        id: 2,
        name: "Yes/No"
    }
]

exports.findType = (id) => {
    const foundType = this.types.find(type => type.id === id);
    if (foundType) { 
        return foundType.name
    }
    return null;
}

exports.contentType =  async (content) => {
    if (content) {
      
        const response = await fetch(content);
        const blob = await response.blob();
        const type = blob.type;

        if (type.startsWith('image/')) {
            return 'image';
        } else if (type.startsWith('video/')) {
            return 'video';
        }
    }
    return null;
}