import React from 'react'
import Sidebar from './Sidebar'

const Layout = (props) => {

    return (
        <>
            <div id="viewport">
                <Sidebar />
                <div id="content">
                    <div id="content_box">
                        {props.children}
                    </div>
                </div>
            </div>

        </>
    )
}

export default Layout