import React from 'react'
import { useNavigate } from 'react-router-dom';
import '../css/layout.css';

const Sidebar = () => {

    const navigate = useNavigate();

    const logout = () => {
        localStorage.removeItem('token')
        navigate("/login");
    }

    return (
        <>
            <div id="sidebar">
                <ul>
                    <li>
                        <button className="btn bg-transparent" onClick={() => navigate('/quiz')}>
                            <i className="fas fa-chess-board"></i> Quizzes
                        </button>
                    </li>
                    <li>
                        <button className="btn bg-transparent" onClick={() => navigate('/competition')}>
                            <i className="fas fa-file"></i> Competition
                        </button>
                    </li>
                    <li>
                        <button className="btn bg-transparent" onClick={() => logout()}>
                            <i className="fas fa-power-off"></i> Logout
                        </button>
                    </li>
                </ul>
            </div>
        </>
    )
}

export default Sidebar