import React, { useState, useEffect } from 'react'
import Layout from '../layout/Layout'
import { deleteCompetition, getAllCompetition } from '../../services/quiz-api';
import { useNavigate, useParams } from 'react-router-dom';


const pageSize = 10; 

const Question = () => {

    const [question, setQuestion] = useState([]);
    const navigate = useNavigate();
    const { quizId } = useParams();

    const [currentPage, setCurrentPage] = useState(() => {
      const storedValue = localStorage.getItem(quizId);
      return storedValue !== null && storedValue !== '' ? parseInt(storedValue) : 1;
    });
  
    const [searchTerm, setSearchTerm] = useState('');
    useEffect(() => {
        getAllCompetition().then((res) => {
            setQuestion(res)
        }).catch((err) => {
            console.log(err)
        })
    }, [])

    const deleteCompetitionById = (id) => {
      deleteCompetition(id).then(res => {
            setQuestion(question.filter(q => q.id !== id))
        }).catch(err => {
            console.log(err)
        })
    }

    const filteredItems = question.filter(item =>
      item.title.toLowerCase().includes(searchTerm.toLowerCase())
    );
  
    // Paginate the filtered items
    const indexOfLastItem = currentPage * pageSize;
    const indexOfFirstItem = indexOfLastItem - pageSize;
    const currentItems = filteredItems.slice(indexOfFirstItem, indexOfLastItem);
  
    // Change page
    // const paginate = pageNumber => setCurrentPage(pageNumber);

    const paginate = (pageNumber) => {
      localStorage.setItem(quizId, pageNumber);
      setCurrentPage(pageNumber);

    }

    return (
        <>
            <Layout>
                <div className="d-flex justify-content-between align-items-center mb-4">
                    <h2>Competition</h2>
                    <button className="btn btn-primary" onClick={() => navigate(`/competition/add`)}>
                        Add Competition
                    </button>
                </div>
                <input
        type="text"
        className="form-control mb-3 searchbox"
        placeholder="Search..."
        value={searchTerm}
        onChange={e => setSearchTerm(e.target.value)}
      />
                <table className="table">
                    <thead>
                        <tr>
                            <th scope="col">#</th>
                            <th scope="col">Title</th>
                            <th scope="col">Date Time</th>
                            <th scope="col">Duration</th>
                            <th scope="col">Random Questions</th>
                            <th scope="col">Status</th>
                            <th scope="col" style={{ width: '12%' }}>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {currentItems && currentItems.length > 0 ?
                            (currentItems.map((list, index) =>
                                <tr key={list.id}>
                                    <th scope="row">{index + 1}</th>
                                    <td className='td-style'>{list.title}</td>
                                    <td>{list.start_date_time.replace(/T/gi, " ").replace(/.000Z/gi, "")}</td>
                                    <td>{list.duration} Second</td>
                                    <td>{list.random_questions}</td>
                                    <td>{list.status === 'A' ? 'Active' : 'Inactive'}</td>
                                    <td className="d-flex justify-content-between">
                                        <button  className="btn btn-primary" onClick={() => navigate(`/competition/edit/${list.id}`)}>
                                            <i className="fas fa-edit"></i>
                                        </button>
                                        <button className="btn btn-primary" onClick={() => deleteCompetitionById(list.id)}>
                                            <i className="fas fa-trash"></i>
                                        </button>
                                        <button  className="btn btn-primary" onClick={() => navigate(`/competition/participants/${list.id}`)}>
                                            <i className="fas fa-user"></i>
                                        </button>
                                    </td>
                                </tr>
                            )
                            )
                            :
                            (<tr style={{ textAlign: "center" }}>
                                <td colSpan={11}>No records found</td>
                            </tr>)}
                    </tbody>
                </table>
                <nav>
        <ul className="pagination mt-3">
          {Array.from({ length: Math.ceil(filteredItems.length / pageSize) }).map((_, index) => (
            <li key={index + 1} className={`page-item ${currentPage === index + 1 ? 'active' : ''}`}>
              <button className="page-link" onClick={() => paginate(index + 1)}>
                {index + 1}
              </button>
            </li>
          ))}
        </ul>
      </nav>
            </Layout>
        </>
    )
}

export default Question