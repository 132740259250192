import React, { useState, useEffect, useRef } from "react";
import Layout from "../layout/Layout";
import { useNavigate, useParams } from "react-router-dom";
import { addCompetition, getAllLevel } from "../../services/quiz-api";
import "../css/login.css";
import "../css/custom.css";
import { levels } from "../../utils/levels";
import { types } from "../../utils/type";  
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import arrowBackIcon from '../icon/arrow-back.svg'; // Adjust the path as per your project structure
import Datetime from 'react-datetime';
 import 'react-datetime/css/react-datetime.css'; 
 import moment from 'moment';

const AddCompetition = () => {
  const navigate = useNavigate();
  const { quizId } = useParams();
  const [previewContent, setPreviewContent] = useState(null);
  const [state, setState] = useState({
    title: "",
    description: "",
    start_date_time: "",
    duration: "",
    random_questions: "",
    status: "",
  });
  const formRef = useRef(null);

  const onSubmit = async () => {
    if (state.title) {
      await addCompetition({
        ...state,
      }).then((res) => {
          toast("Data has been successfully inserted.")
          navigate('/competition')
      }).catch((err) => {
          if(err.error) {
            toast(err.error)
          }
          console.log(err);
      });
    }
  };


  const handleTypeChange = (event) => {
    setState({
      ...state,
      status: event.target.value,
    });
  };

  useEffect(() => {
    //console.log("state ", state);
  })
  return (
    <>
       <ToastContainer />
      <Layout>
      <p onClick={() => navigate(-1)} className="back-pre"><img src={arrowBackIcon} alt="Back" /> Back</p> 
        <div className="d-flex">
        
          <h2> Add Competition</h2>
        </div>

        <div className="authincation-content">
          <section className="login-part">
            <div className="container">
              <div className="login">
                <div className="login-card">
                  <div className="card">
                    <form ref={formRef}>
                      <div className="card-body">
                        
                        <div className="mb-4">
                          <label htmlFor="title" className="form-label">
                            Title
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="title"
                            placeholder="Enter title"
                            value={state.title}
                            onChange={(event) =>
                              setState({ ...state, title: event.target.value })
                            }
                            autoComplete="off"
                          />
                        </div>
                        <div className="mb-4">
                          <label htmlFor="title" className="form-label">
                          Description
                          </label>
                          <textarea
                            className="form-control"
                            id="description"
                            placeholder="Enter description"
                            value={state.description}
                            onChange={(event) =>
                              setState({ ...state, description: event.target.value })
                            }
                            autoComplete="off"
                          />
                        </div>
                        <div className="mb-4">
                          <label htmlFor="title" className="form-label">
                          Start Date Time
                          </label>
                          <Datetime
                            value={state.start_date_time}
                            onChange={(selectedDate) =>
                              setState({ ...state, start_date_time: moment(selectedDate).format('YYYY-MM-DD HH:mm:ss') })
                            }
                            inputProps={{ placeholder: 'Select Date and Time' }}
                            dateFormat="YYYY-MM-DD"
                            timeFormat="HH:mm:ss"
                            isValidDate={(current) => current.isSameOrAfter(moment(), 'day')}
                          />
                       
                        </div>
                        <div className="mb-4">
                          <label htmlFor="title" className="form-label">
                          Duration (Second)
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="title"
                            placeholder="Enter duration"
                            value={state.duration}
                            onChange={(event) => {
                              const input = event.target.value.replace(/\D/g, ''); // Remove non-numeric characters
                              setState({ ...state, duration: input });
                            }}
                            onInput={(event) => {
                              event.target.value = event.target.value.replace(/\D/g, ''); // Remove non-numeric characters
                            }}
                            inputMode="numeric" 
                            autoComplete="off"
                          />
                        </div>
                        <div className="mb-4">
                          <label htmlFor="title" className="form-label">
                          Number of random questions
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="title"
                            placeholder="Enter random questions"
                            value={state.random_questions}
                            onChange={(event) => {
                              const input = event.target.value.replace(/\D/g, ''); // Remove non-numeric characters
                              setState({ ...state, random_questions: input });
                            }}
                            onInput={(event) => {
                              event.target.value = event.target.value.replace(/\D/g, ''); // Remove non-numeric characters
                            }}
                            inputMode="numeric" 
                            autoComplete="off"
                          />
                        </div>
                        <div className="mb-4">
                          <label htmlFor="type" className="form-label">
                            Status
                          </label>
                          <div className="d-flex justify-content-start">
                              <div>
                                <label>
                                  <input
                                    type="radio"
                                    name="status"
                                    value='A'
                                    onChange={handleTypeChange}
                                  />
                                  Active
                                </label>
                              </div>

                              <div className="ms-3">
                                <label>
                                  <input
                                    type="radio"
                                    name="status"
                                    value='D'
                                    onChange={handleTypeChange}
                                  />
                                  Inactive
                                </label>
                              </div>
                          </div>
                        </div>
                     
                        
                       
                        
                        

                        
                        <div className="form-login-button pt-4">
                          <button type="button" onClick={onSubmit}  >Save</button>
                          
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </Layout>
    </>
  );
};

export default AddCompetition;
