import React, { useState, useEffect } from 'react'
import Layout from '../layout/Layout'
import { getAllQuiz } from '../../services/quiz-api';
import { useNavigate } from 'react-router-dom';

const pageSize = 10; 

const Quiz = () => {

    const [quiz, setQuiz] = useState([]);
    const navigate = useNavigate();
    const [currentPage, setCurrentPage] = useState(1);
    const [searchTerm, setSearchTerm] = useState('');
    const [searchResult, setSearchResult] = useState('');
    const [filterQuiz, setFilterQuiz] = useState([]);


    useEffect(() => {
        getAllQuiz().then((res) => {
            // customize the list to show English Quiz first if it exists
            let resultData = res;
            if(resultData.length > 0){
                resultData = filterQuizess(resultData);
                console.log("Final Data", resultData);
            }
            setFilterQuiz(resultData);
            setQuiz(resultData);
        }).catch((err) => {
            console.log(err)
        })
    }, [])

    const filterQuizess = (originalArray) => {
        let resultingEnglishArray = originalArray.filter((currentItem) => currentItem.language_id === 0);
        let resultingFrenchArray = originalArray.filter((currentItem) => currentItem.language_id === 1);
        let resultingEnglishIdArray = [];
        if(resultingEnglishArray.length > 0){
            resultingEnglishIdArray = resultingEnglishArray.map((currentItem) => currentItem.id);
        }
        if(resultingEnglishIdArray.length > 0)
            resultingFrenchArray = originalArray.filter((currentItem) => !resultingEnglishIdArray.includes(currentItem.id));
        return [...resultingEnglishArray, ...resultingFrenchArray];
    }

    // const filteredItems = quiz.filter(item =>
    //     item.name.toLowerCase().includes(searchTerm.toLowerCase())
    //   );
    
    // Paginate the filtered items
    //   const indexOfLastItem = currentPage * pageSize;
    //   const indexOfFirstItem = indexOfLastItem - pageSize;
    //   const currentItems = filteredItems.slice(indexOfFirstItem, indexOfLastItem);
    // const currentItems = quiz;
    
    // Change page
    // const paginate = pageNumber => setCurrentPage(pageNumber);

    useEffect(() => {
    //   console.log("quiz ******", quiz);
    });


    const handleSearchData = (e) => {
        setSearchTerm(e.target.value);
        let searchValue = e.target.value;
        if(searchValue){
            const filteredItems = quiz.filter(item =>
                item.name.toLowerCase().includes(searchValue.toLowerCase())
            );
            setFilterQuiz(filteredItems);
        }  else 
        setFilterQuiz(quiz);       
    }
    

    return (
        <>
            <Layout>
                <div className="d-flex justify-content-between align-items-center mb-4">
                    <h2>Quizzes</h2>
                    <button className="btn btn-primary" onClick={() => navigate('/quiz/add')}>
                        Add Quiz
                    </button>
                </div>
                <input
        type="text"
        className="form-control mb-3 searchbox"
        placeholder="Search..."
        value={searchTerm}
        onChange={e => handleSearchData(e)}
      />
                <table className="table">
                    <thead>
                        <tr>
                            <th scope="col">#</th>
                            <th scope="col">Image</th>
                            <th scope="col">Name</th>
                            <th scope="col">Description</th>
                            <th scope="col">Featured</th>
                            <th scope="col">Popular</th>
                            <th scope="col">Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {filterQuiz && filterQuiz.length > 0 ?
                            (filterQuiz.map((list, index) =>
                                <tr key={list.id}>
                                    <th scope="row">{index + 1}</th>
                                    <th scope="row">{list?.image ? <img src={list?.image} style={{maxWidth: "50px"}}/> : ""}</th>
                                    <td className='td-style'>{list.name}</td>
                                    <td>{list.description}</td>
                                    <td>{list.isFeatured ? 'Yes' : 'No'}</td>
                                    <td>{list.isPopular ? 'Yes' : 'No'}</td>
                                    <td className="d-flex justify-content-center align-items-center">
                                        <button style={{ marginRight: '5px' }} className="btn btn-primary" onClick={() => navigate(`/quiz/edit/${list.id}`)}>
                                            <i className="fas fa-edit"></i>
                                        </button>
                                        <button className="btn btn-primary" onClick={() => navigate(`/quiz/question/${list.id}`)}>
                                            <i className="fas fa-question"></i>
                                        </button>
                                    </td>
                                </tr>
                            )
                            )
                            :
                            (<tr style={{ textAlign: "center" }}>
                                <td colSpan={6}>No records found</td>
                            </tr>)}
                    </tbody>
                </table>
                <nav>
        {/* <ul className="pagination mt-3">
          {Array.from({ length: Math.ceil(filteredItems.length / pageSize) }).map((_, index) => (
            <li key={index + 1} className={`page-item ${currentPage === index + 1 ? 'active' : ''}`}>
              <button className="page-link" onClick={() => paginate(index + 1)}>
                {index + 1}
              </button>
            </li>
          ))}
        </ul> */}
      </nav>
            </Layout>
        </>
    )
}

export default Quiz