import React, { useState, useEffect } from 'react'
import Layout from '../layout/Layout'
import { deleteCompetition, getAllParticipants } from '../../services/quiz-api';
import { useNavigate, useParams } from 'react-router-dom';


const pageSize = 10; 

const Question = () => {
    const { id } = useParams();
    const [question, setQuestion] = useState([]);
    const navigate = useNavigate();
    const { quizId } = useParams();

    const [currentPage, setCurrentPage] = useState(() => {
      const storedValue = localStorage.getItem(quizId);
      return storedValue !== null && storedValue !== '' ? parseInt(storedValue) : 1;
    });
  
    const [searchTerm, setSearchTerm] = useState('');
    useEffect(() => {
        getAllParticipants(id).then((res) => {
            setQuestion(res)
        }).catch((err) => {
            console.log(err)
        })
    }, [id])

    const deleteCompetitionById = (id) => {
      deleteCompetition(id).then(res => {
            setQuestion(question.filter(q => q.id !== id))
        }).catch(err => {
            console.log(err)
        })
    }

    const filteredItems = question.filter(item =>
      item.competition.title.toLowerCase().includes(searchTerm.toLowerCase())
    );
  
    // Paginate the filtered items
    const indexOfLastItem = currentPage * pageSize;
    const indexOfFirstItem = indexOfLastItem - pageSize;
    const currentItems = filteredItems.slice(indexOfFirstItem, indexOfLastItem);
  
    // Change page
    // const paginate = pageNumber => setCurrentPage(pageNumber);

    const paginate = (pageNumber) => {
      localStorage.setItem(quizId, pageNumber);
      setCurrentPage(pageNumber);

    }

    const convertSecondsToMinutesAndSeconds = (totalSeconds) => {
        const minutes = Math.floor(totalSeconds / 60);
        const seconds = totalSeconds % 60;
        return `${minutes < 10 ? '0' : ''}${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
      };
    return (
        <>
            <Layout>
                <div className="d-flex justify-content-between align-items-center mb-4">
                    <h2>Participant</h2>
                    
                </div>
                {/* <input
        type="text"
        className="form-control mb-3 searchbox"
        placeholder="Search..."
        value={searchTerm}
        onChange={e => setSearchTerm(e.target.value)}
      /> */}
                <table className="table">
                    <thead>
                        <tr>
                            <th scope="col">#</th>
                            <th scope="col">Name </th>
                            <th scope="col">Phone No </th>
                            <th scope="col">Right Score</th>
                            <th scope="col">Wrong Score</th>
                            <th scope="col">Duration Time</th>
                        </tr>
                    </thead>
                    <tbody>
                        {currentItems && currentItems.length > 0 ?
                            (currentItems.map((list, index) =>
                                <tr key={list.id}>
                                    <th scope="row">{index + 1}</th>
                                    <td className='td-style'>{list.participant.name}</td>
                                    <td>{list.participant.mobileNumber}</td>
                                    <td>{list.rightScore}</td>
                                    <td>{list.wrongScore}</td>
                                    <td>{convertSecondsToMinutesAndSeconds(list.durationTime)}</td>
                                    
                                </tr>
                            )
                            )
                            :
                            (<tr style={{ textAlign: "center" }}>
                                <td colSpan={11}>No records found</td>
                            </tr>)}
                    </tbody>
                </table>
                <nav>
        <ul className="pagination mt-3">
          {Array.from({ length: Math.ceil(filteredItems.length / pageSize) }).map((_, index) => (
            <li key={index + 1} className={`page-item ${currentPage === index + 1 ? 'active' : ''}`}>
              <button className="page-link" onClick={() => paginate(index + 1)}>
                {index + 1}
              </button>
            </li>
          ))}
        </ul>
      </nav>
            </Layout>
        </>
    )
}

export default Question