export const languages = [
    { id: 0, name: "ENGLISH" },
    { id: 1, name: "FRENCH" },
    { id: 2, name: "ARABIC" },
    { id: 3, name: "PORTUGUESE" },
    { id: 4, name: "SWAHILI" },
    { id: 5, name: "AMHARIC" },
    { id: 6, name: "SPANISH" },
    { id: 7, name: "SHONA" },
    { id: 8, name: "TOK PISIN" },
];
