import React, { useState, useEffect } from 'react'
import Layout from '../layout/Layout'
import { deleteQuestion, getAllQuestion } from '../../services/quiz-api';
import { useNavigate, useParams } from 'react-router-dom';
import { findLevel } from '../../utils/levels';
import { findType } from '../../utils/type';

const pageSize = 10; 
const checkContentType = async (url) => {
    try {
      const response = await fetch(url);
      const contentType = response.headers.get('content-type');
  
      if (contentType && contentType.startsWith('image/')) {
        return 'image';
      } else if (contentType && contentType.startsWith('video/')) {
        return 'video';
      } else {
        return 'unknown';
      }
    } catch (error) {
      console.error('Error checking content type:', error);
      return 'error';
    }
  };
  const MyComponent = ({ url }) => {
    const [contentType, setContentType] = useState(null);
  
    useEffect(() => {
      const fetchContentType = async () => {
        if (url) {
          const type = await checkContentType(url);
          setContentType(type);
        }
      };
  
      fetchContentType();
    }, [url]);
  
    const renderContent = () => {
      switch (contentType) {
        case 'image':
          return <><img src={url} style={{ maxWidth: '50px' }} alt="Image" /><br />Image</> ;
        case 'video':
         // return <video controls width="50" src={url} />;
          return <><img src='../../../image/video.jpg' style={{ maxWidth: '50px' }} alt="Image" /><br />Video</>  ;
        case 'unknown':
          return <p>Unknown content type</p>;
        case 'error':
          return <p>Error checking content type</p>;
        default:
          return null;
      }
    };
  
    return <div>{renderContent()}</div>;
  };
  
const Question = () => {

    const [question, setQuestion] = useState([]);
    const navigate = useNavigate();
    const { quizId } = useParams();

    const [currentPage, setCurrentPage] = useState(() => {
      const storedValue = localStorage.getItem(quizId);
      return storedValue !== null && storedValue !== '' ? parseInt(storedValue) : 1;
    });
  
    const [searchTerm, setSearchTerm] = useState('');
    useEffect(() => {
        getAllQuestion(quizId).then((res) => {
            setQuestion(res)
        }).catch((err) => {
            console.log(err)
        })
    }, [])

    const deleteQuestionById = (id) => {
        deleteQuestion(id).then(res => {
            setQuestion(question.filter(q => q.id !== id))
        }).catch(err => {
            console.log(err)
        })
    }

    const filteredItems = question.filter(item =>
      item.title.toLowerCase().includes(searchTerm.toLowerCase())
    );
  
    // Paginate the filtered items
    const indexOfLastItem = currentPage * pageSize;
    const indexOfFirstItem = indexOfLastItem - pageSize;
    const currentItems = filteredItems.slice(indexOfFirstItem, indexOfLastItem);
  
    // Change page
    // const paginate = pageNumber => setCurrentPage(pageNumber);

    const paginate = (pageNumber) => {
      localStorage.setItem(quizId, pageNumber);
      setCurrentPage(pageNumber);

    }

    return (
        <>
            <Layout>
                <div className="d-flex justify-content-between align-items-center mb-4">
                    <h2>Questions</h2>
                    <button className="btn btn-primary" onClick={() => navigate(`/quiz/question/${quizId}/add`)}>
                        Add Question
                    </button>
                </div>
                <input
        type="text"
        className="form-control mb-3 searchbox"
        placeholder="Search..."
        value={searchTerm}
        onChange={e => setSearchTerm(e.target.value)}
      />
                <table className="table">
                    <thead>
                        <tr>
                            <th scope="col">#</th>
                            <th scope="col">Type</th>
                            <th scope="col">Title</th>
                            <th scope="col">Level</th>
                            <th scope="col">Type</th>
                            <th scope="col">Option 1</th>
                            <th scope="col">Option 2</th>
                            <th scope="col">Option 3</th>
                            <th scope="col">Option 4</th>
                            <th scope="col">Correct Answer</th>
                            <th scope="col">Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {currentItems && currentItems.length > 0 ?
                            (currentItems.map((list, index) =>
                                <tr key={list.id}>
                                    <th scope="row">{index + 1}</th>
                                    <th class="content-image-video"><MyComponent key={index} url={list?.image} /></th>
                                    <td className='td-style'>{list.title}</td>
                                    <td>{findLevel(list.levelId)}</td>
                                    <td>{findType(list.type)}</td>
                                    <td>{list.option_1}</td>
                                    <td>{list.option_2}</td>
                                    <td>{list.option_3}</td>
                                    <td>{list.option_4}</td>
                                    <td>{list.correctAnswer}</td>
                                    <td className="custom-question">
                                        <button style={{ marginRight: '5px' }} className="btn btn-primary" onClick={() => navigate(`/quiz/question/${quizId}/edit/${list.id}`)}>
                                            <i className="fas fa-edit"></i>
                                        </button>
                                        <button className="btn btn-primary" onClick={() => deleteQuestionById(list.id)}>
                                            <i className="fas fa-trash"></i>
                                        </button>
                                    </td>
                                </tr>
                            )
                            )
                            :
                            (<tr style={{ textAlign: "center" }}>
                                <td colSpan={11}>No records found</td>
                            </tr>)}
                    </tbody>
                </table>
                <nav>
        <ul className="pagination mt-3">
          {Array.from({ length: Math.ceil(filteredItems.length / pageSize) }).map((_, index) => (
            <li key={index + 1} className={`page-item ${currentPage === index + 1 ? 'active' : ''}`}>
              <button className="page-link" onClick={() => paginate(index + 1)}>
                {index + 1}
              </button>
            </li>
          ))}
        </ul>
      </nav>
            </Layout>
        </>
    )
}

export default Question