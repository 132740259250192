exports.levels = [
    {
        id: 1,
        name: "Beginner"
    },
    {
        id: 2,
        name: "Intermediate"
    },
    {
        id: 3,
        name: "Advance"
    }
]

exports.findLevel = (id) => {
    //return this.levels.find(level => level.id === id)
    const foundLevel = this.levels.find(level => level.id === id);
    if (foundLevel) { 
        return foundLevel.name
    }
    return null;
}