import { useSession } from "providers/SessionProvider";
import { Outlet, Navigate } from "react-router-dom";

const AuthGuard = ({ isProtected = true }) => {
    const { getAccessToken } = useSession();

    return (
        isProtected
            ? Boolean(getAccessToken())
                ? <Outlet /> : <Navigate to="/login" />
            : !Boolean(getAccessToken())
                ? <Outlet /> : <Navigate to="/" />
    );
}

export default AuthGuard;
